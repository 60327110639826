<template lang="pug">
  Section.cy-insurance-section(
    :title="$t('plan_settings.basic_settings.sections.insurance.section_name')"
  )
    .block
      .section-content-header
        span {{ isSet ? $t("plan_settings.basic_settings.set") : $t("plan_settings.basic_settings.not_set") }}
        SwitchComponent(
          :is-hidden="hidden"
          @click="toggleTable"
        )
      .block(v-if="!hidden")
        .condition-text-container
          span.condition-text {{ $t("price_calendar.filters.toggler.optional") }}
        .insurance-container
          span {{ $t("plan_settings.basic_settings.sections.insurance.additional_insurance") }}
          AppDropdown.cy-optional-selectable-insurance(
            checkbox
            multiple
            allow-empty
            order-direction="keep"
            :items="insurances"
            :value="optionalSelectableInsurances"
            :placeholder="$t('actions.select')"
            @select="selectInsurances($event, optional, selectable)"
          )
        .condition-text-container
          span.condition-text {{ $t("price_calendar.filters.toggler.included") }}
        .insurance-container
          span {{ $t("plan_settings.basic_settings.sections.insurance.standard_insurance") }}
          AppDropdown.cy-included-standard-insurance(
            checkbox
            multiple
            allow-empty
            order-direction="keep"
            :items="insurances"
            :value="includedStandardInsurances"
            :placeholder="$t('actions.select')"
            :is-item-disabled="item => isItemDisabled(item, standard)"
            @select="selectInsurances($event, included, standard)"
          )
        .insurance-container
          span {{ $t("plan_settings.basic_settings.sections.insurance.additional_insurance") }}
          AppDropdown.cy-included-selectable-insurance(
            checkbox
            multiple
            allow-empty
            order-direction="keep"
            :items="insurances"
            :value="includedSelectableInsurances"
            :placeholder="$t('actions.select')"
            :is-item-disabled="item => isItemDisabled(item, selectable)"
            @select="selectInsurances($event, included, selectable)"
          )
        .condition-text-container
          span.condition-text {{ $t("plan_settings.basic_settings.sections.insurance.fee_settings") }}
          span.text-muted.m-l-8.m-t-12 {{ $t("plan_settings.basic_settings.sections.insurance.required_when") }}
        .m-t-12
          InsuranceTable(
            :all-insurances="object"
            :merged-insurances="mergedInsurances"
          )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // cancellation fee rules store module
  import insurancesModule from "@/config/store/price_management/plan_settings/insurances"

  import { findKey, forEach, uniq } from "lodash-es"
  import { EVERY_DAY, INCLUDED, OPTIONAL, NO, STANDARD, SELECTABLE } from "@/config/constants"
  import { getPairedInsurance, hasInsurance, mergePairedInsurances, filterMapInsurances } from "@/helpers/insurances"
  import { resetValidatorValues } from "@/helpers/common"

  const insurancesMixin = withStoreModule(insurancesModule, {
    name: "insurances",
    readers: { insurances: "items" },
    actions: { fetchInsurances: "FETCH_ITEMS" }
  })

  export default {
    props: {
      object: Object
    },

    mixins: [insurancesMixin],

    mounted() {
      this.fetchInsurances()
    },

    data() {
      return {
        hidden: true,
        everyDay: EVERY_DAY,
        optional: OPTIONAL,
        included: INCLUDED,
        no: NO,
        selectable: SELECTABLE,
        standard: STANDARD
      }
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      InsuranceTable: () => import("./InsuranceTable"),
      SwitchComponent: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Shared/SwitchComponent")
    },

    computed: {
      isSet() {
        return (
          findKey(this.object.$each.$iter, function(o) {
            return o.fee.$model > 0 || o.max_fee.$model > 0
          }) !== undefined
        )
      },

      optionalSelectableInsurances() {
        return filterMapInsurances(this.object.$model, this.optional, this.selectable)
      },

      includedStandardInsurances() {
        return filterMapInsurances(this.object.$model, this.included, this.standard)
      },

      includedSelectableInsurances() {
        return filterMapInsurances(this.object.$model, this.included, this.selectable)
      },

      mergedInsurances() {
        let insurances = []
        let ids = uniq([...this.includedSelectableInsurances, ...this.optionalSelectableInsurances].map(({ id }) => id))
        forEach(this.object.$each.$iter, currentInsurance => {
          const { sources_insurance, selectability_type, insurance_type } = currentInsurance

          const sourceInsuranceId = sources_insurance.id.$model
          const insuranceType = insurance_type.$model
          const selectabilityType = selectability_type.$model

          if (
            ids.includes(sourceInsuranceId) &&
            !hasInsurance(insurances, sourceInsuranceId) &&
            selectabilityType === this.selectable
          ) {
            const paired = getPairedInsurance(this.object.$each.$iter, sourceInsuranceId, insuranceType, true)
            const finalInsurance = paired || currentInsurance
            insurances.push(finalInsurance)
          }
        })
        return insurances
      }
    },

    watch: {
      object: {
        handler(newObject) {
          if (newObject.$invalid) this.hidden = false
        },
        deep: true
      }
    },

    methods: {
      toggleTable() {
        this.object.$reset()
        this.$nextTick(() => {
          this.hidden = !this.hidden
        })
      },

      selectInsurances(items, selected_insurance_type, new_selectability_type) {
        const insuranceIds = items.map(({ id }) => id)
        forEach(this.object.$each.$iter, insurance => {
          const {
            insurance_type,
            selectability_type,
            sources_insurance: { id },
            fee_type
          } = insurance
          if (insurance_type.$model === selected_insurance_type) {
            if (insuranceIds.includes(id.$model)) {
              selectability_type.$model = new_selectability_type
              fee_type.$reset()
            } else if (selectability_type.$model === new_selectability_type) {
              selectability_type.$model = this.no
              resetValidatorValues(insurance, ["fee_type", "fee", "max_fee"])
            }
          }

          mergePairedInsurances(this.object.$each.$iter, insurance)
        })
      },

      isItemDisabled(item, selectability_type) {
        const scope =
          selectability_type === this.selectable ? this.includedStandardInsurances : this.includedSelectableInsurances
        return !!scope.find(({ id }) => item.id === id)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .block
    +block

    .section-content-header
      +section-content-header

    .condition-text
      background: $default-purple-light
      border-radius: 4px
      font-size: 0.8rem
      font-weight: bold
      margin-top: 12px
      text-decoration: underline
      padding-inline: 10px

      &-container
        align-items: center
        display: flex
        justify-content: flex-start

    .insurance-container
      @extend .condition-text-container
      justify-content: space-between

      ::v-deep
        .app-select
          width: 50%
</style>
