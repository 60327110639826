import { find, reduce } from "lodash-es"
import { SELECTABLE, EVERY_DAY } from "@/config/constants"

export const getPairedInsurance = (scope, id, insurance_type, onlyDirty = false) => {
  return find(scope, insurance => {
    const mainConditions =
      insurance.sources_insurance.id.$model === id &&
      insurance.insurance_type.$model !== insurance_type &&
      insurance.selectability_type.$model === SELECTABLE

    const dirtyConditions = onlyDirty ? isDirtyInsurance(insurance) : true

    return mainConditions && dirtyConditions
  })
}

const isDirtyInsurance = ({ fee_type, fee, max_fee }) =>
  (fee_type.$model !== null && fee_type.$model !== EVERY_DAY) || fee.$model !== null || max_fee.$model !== null

export const hasInsurance = (insurances, id) =>
  !!find(insurances, ({ sources_insurance }) => sources_insurance.id.$model === id)

export const mergePairedInsurances = (
  scope,
  { selectability_type, sources_insurance: { id }, insurance_type, fee_type, fee, max_fee }
) => {
  if (selectability_type.$model === SELECTABLE) {
    const pairedInsurance = getPairedInsurance(scope, id.$model, insurance_type.$model, true)
    if (pairedInsurance) {
      fee_type.$model = pairedInsurance.fee_type.$model
      fee.$model = pairedInsurance.fee.$model
      max_fee.$model = pairedInsurance.max_fee.$model
    }
  }
}

export const filterMapInsurances = (array, insuranceType, selectabilityType) =>
  reduce(
    array,
    (filtered, item) => {
      const {
        sources_insurance: { id, name }
      } = item
      if (item.insurance_type === insuranceType && item.selectability_type === selectabilityType) {
        filtered.push({ id, name })
      }

      return filtered
    },
    []
  )
